import React, { useCallback, useEffect, useState } from "react"
import styled from "styled-components"
import { graphql, navigate } from "gatsby"
import Layout from "../template/Layout"
import Head from "../head"
import ArticleListCard from "../components/blog/ArticleListCard"
import { BlogCategoriesJa, BlogCategoryColors, BlogCategoryTags, RESPONSIVE_STYLES, OWNEDMEDIA_WORDPRESS_URL } from '../AppConst'
import { ReadMore } from "../components/common/ReadMore"
import TagList from "../components/common/TagList"

import {WPFrontMatter} from "../types/WPFrontMatter"
import { LoadingAPI } from "../components/common/LoadingAPI"
import { ConnectionFailed } from "../components/connection_failed"
import { ConnectionFailedText } from "../components/common/ConnectionFailedText"

/**
 *@ Element & Styles
 */
const CategoryBlogsWrapper = styled.div`
display: flex;
flex-wrap: wrap;
gap: 6.4rem;
@media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
  gap: 4rem;
}
`

const CategoryBlogWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  gap: 2.4rem;
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    flex-direction: column;
    gap: 1.6rem;
  }
`

const Category = styled.span<{ categoryColor: string }>`
  position: relative;
  padding-left: 24px;
  font-weight: 700;
  font-size: 2.4rem;
  line-height: 3.6rem;
  letter-spacing: 0.12em;
  color: #222222;
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    font-size: 2rem;
    line-height: 3rem;
    letter-spacing: 0.1em;
  }
  &::before {
    content: '';
    width: 16px;
    height: 16px;
    background-color: ${props => props.categoryColor};
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }
`

const ReadMoreWrapper = styled.div`
  padding-right: 10px;
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    order: 2;
    display: flex;
    justify-content: end;
  }
  & > div {
    margin: unset;
  }
`

const ArticlesWrapper = styled.div`
  display: flex;
  gap: 2.4rem;
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    flex-direction: column;
    gap: 1.6rem;
  }
`

const breadcrumbsData: { text: string; path: string }[] = [
  { text: 'BLOG', path: 'blog' },
]

const fetchCategoryPosts = async (categorySlug, categoryPosts, setHasError) => {
  // keyを使用してcategoryのidを取得
  fetch(`${OWNEDMEDIA_WORDPRESS_URL}/wp-json/wp/v2/categories/?slug=${categorySlug}`)
  .then(response => {return response.json()})
  .then(categoryData => {
    // 記事一覧から上記のcategory_idを持つ記事から最新の3件を取得
    fetch(`${OWNEDMEDIA_WORDPRESS_URL}/wp-json/wp/v2/posts?_embed&categories=${categoryData[0].id}&per_page=3`)
    .then(response => {return response.json()})
    .then(postsData => {
      const posts: WPFrontMatter[] = [];

      postsData.map((data, i) => {
        const postData: WPFrontMatter = {
          title: data.title.rendered,
          date: data.date,
          category: data._embedded["wp:term"][0][0].slug,
          description: "",
          author: data._embedded.author[0].name,
          thumbnail: data._embedded["wp:featuredmedia"][0].source_url,
          slug: `${data._embedded.author[0].slug}/${data.slug}`
        }
        posts.push(postData);
      })

      categoryPosts(posts);
    })
  })
  .catch((error) => {
    setHasError(true);
  });
};

export default function Blog({ data }) {
  const [hasError, setHasError] = useState(false);
  const [hrPosts, setHrPosts] = useState<WPFrontMatter[]>();
  const [serviceProductPosts, setServiceProductPosts] = useState<WPFrontMatter[]>();
  const [creativePosts, setCreativePosts] = useState<WPFrontMatter[]>();
  const [recruitmentPosts, setRecruitmentPosts] = useState<WPFrontMatter[]>();
  const [entertainmentPosts, setEntertainmentPosts] = useState<WPFrontMatter[]>();
  const [informationPosts, setInformationPosts] = useState<WPFrontMatter[]>();

  useEffect(() => {
    fetchCategoryPosts('hr', setHrPosts, setHasError);
    fetchCategoryPosts('service-product', setServiceProductPosts, setHasError);
    fetchCategoryPosts('creative', setCreativePosts, setHasError);
    fetchCategoryPosts('recruitment', setRecruitmentPosts, setHasError);
    fetchCategoryPosts('entertainment', setEntertainmentPosts, setHasError);
    fetchCategoryPosts('information', setInformationPosts, setHasError);
  }, []);

  const handleClick = useCallback((tagValue: string) => {
    navigate(`/tags/${tagValue}/`);
  }, [])

  const getCategoryPosts = (key: string) => {
    switch(key){
      case 'hr': return hrPosts
      case 'service-product': return serviceProductPosts
      case 'creative': return creativePosts
      case 'recruitment': return recruitmentPosts
      case 'entertainment': return entertainmentPosts
      case 'information': return informationPosts
    }
  }

  if (hasError) return <ConnectionFailed />

  return (
    <Layout sidebar={true} hasBlogTitle={true} breadcrumbsData={breadcrumbsData} >
      <Head
        title="ブログトップ | ネクストライブ株式会社"
        description="ネクストライブ株式会社のブログページです。
                    テクノロジー、クリエイティブ、プログラミング、ビジネス、ワークスタイル、
                    ライフスタイル、エンタメ、お知らせのブログ記事を掲載しています。"
      />
      <CategoryBlogsWrapper>
        {
          Object.keys(BlogCategoriesJa).map((key) => {
            return (
              <CategoryBlogWrapper key={key}>
                <Category categoryColor={BlogCategoryColors[key]}>{BlogCategoriesJa[key]}</Category>
                <ReadMoreWrapper>
                  <ReadMore url={`/${key}`} darkMode={true} text='もっと見る' />
                </ReadMoreWrapper>
                { BlogCategoryTags[key].length > 1 && <TagList values={BlogCategoryTags[key]} onClick={handleClick} />}
                {!getCategoryPosts(key) ? <LoadingAPI /> :
                  <ArticlesWrapper>
                    {
                      getCategoryPosts(key)?.map((post, i) => {
                        return (
                          <ArticleListCard
                            key={i}
                            frontMatter={post}
                            members={data.site.siteMetadata.member}
                          />
                        )
                      })
                    }
                  </ArticlesWrapper>
                }
              </CategoryBlogWrapper>
            )
          })
        }
      </CategoryBlogsWrapper>
    </Layout>
  )
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        member {
          name
          filename
        }
      }
    }
  }
`